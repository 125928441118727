module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://nicolasjewelry.gr","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":""},"googleTagManager":{"trackingId":"GTM-W4CMSBC","cookieName":"gdpr-acceptance"},"facebookPixel":{"pixelId":"346644046839490","cookieName":"gdpr-acceptance"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicolas Jewelry Store","short_name":"Nicolas Jewelry","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/assets/icons/logo_min_black.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89139c043608ae30a21ffd488e45d6c0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c5a557"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
